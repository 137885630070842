@use '@angular/material' as mat;

:root {
  --inthraction-primary-color: #ee6934;
  --amplify-primary-color: #ee6934;
  --amplify-colors-brand-primary-10: #fbdcd0;
  --amplify-colors-brand-primary-80: #ee6934;
  --amplify-colors-brand-primary-90: #a5380d;
  --amplify-colors-brand-primary-100: #5e2008;

  --color-primary: #FE5305;
  --color-primary-highlight: #f39670;

  --font-family: Roboto, Helvetica Neue, sans-serif;
  --mdc-typography-headline6-font-weight: 800;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*, *::before, *::after {
  box-sizing: inherit;
}

$inthraction-primary-color: var(--inthraction-primary-color);

$mat-inthraction-orange: (
  50: #ffeae1,
  100: #ffcbb4,
  200: #ffa982,
  300: #fe8750,
  400: #fe6d2b,
  500: #fe5305,
  600: #fe4c04,
  700: #fe4204,
  800: #fe3903,
  900: #fd2901,
  A100: #ffffff,
  A200: #fff2f1,
  A400: #ffc5be,
  A700: #ffaea4,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: black,
  )
);

$inthraction-app-primary: mat.define-palette($mat-inthraction-orange);
$inthraction-app-accent: mat.define-palette(mat.$deep-orange-palette);
$inthraction-app-warn: mat.define-palette(mat.$red-palette);
$inthraction-app-theme: mat.define-light-theme((
  color: (
    primary: $inthraction-app-primary,
    accent: $inthraction-app-accent,
    warn: $inthraction-app-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-typographies();
@include mat.core();
@include mat.all-component-themes($inthraction-app-theme);

// Override weird css from amplify import above
span {
  color: unset;
}

@import 'node_modules/ngx-toastr/toastr';
@import 'node_modules/angular-calendar/css/angular-calendar';

mat-form-field.slide-toggle-form-field {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-bottom: unset;
    margin-bottom: -8px;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  margin-bottom: 8px;
}

mat-card {

  .mat-mdc-card-header {
    padding: 8px 8px 0;
  }

  .mat-mdc-card-content {
    padding: 0 8px;
  }

  .mat-mdc-card-content:first-child {
    padding-top: 8px;
  }

  .mat-mdc-card-content:last-child {
    padding-bottom: 8px;
  }

  .mdc-card__actions {
    flex-direction: row-reverse;
  }
}

mat-tab-group[vertical] {
  display: flex;
  flex-direction: row !important;

  .mat-mdc-tab-labels {
    display: flex;
    flex-direction: column !important;
  }

  .mat-mdc-tab-body-wrapper {
    width: 100%;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: unset !important;
}

.mat-mdc-dialog-actions.mdc-dialog__actions {
  justify-content: end;
}

.mat-mdc-form-field {
  padding-top: 6px;
}

.mat-mdc-slide-toggle {

  --mdc-switch-disabled-selected-handle-color: #f4511e;
  -mdc-switch-selected-icon-color: #fff;

  .mdc-switch.mdc-switch--selected:disabled .mdc-switch__icon {
    fill: var(--mdc-switch-selected-icon-color, var(--mdc-theme-on-primary, #fff)) !important;
  }

  .mdc-switch.mdc-switch--selected:disabled .mdc-switch__icons {
    opacity: unset !important;
  }
}

h1.mat-dialog-title, h1.mdc-dialog__title {
  --mdc-dialog-subhead-size: 32px;
}

textarea[cdkTextareaAutosize] {
  box-sizing: content-box;
}

html {
  font-size: 16px;
  font-weight: 500;
}

body {
  font-family: var(--font-family);
  font-size: 1rem;
  line-height: 1.5;
  color: #373a3c;
  background-color: #fff;
}

h1 {
  font-weight: bold;
}

footer {
  display: block;
  background: #f3f3f3;
  padding: 17px 0;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  background-color: transparent;
}

.logo-font {
  font-family: titillium web, sans-serif;
}

.amplify-alert {
  margin-top: -5em;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

footer .logo-font {
  vertical-align: middle;
}

footer .attribution {
  vertical-align: middle;
  margin-left: 10px;
  font-size: .8rem;
  color: #bbb;
  font-weight: 300;
}

.inthraction-highlight {
  color: $inthraction-primary-color;
  font-weight: bolder;
}

.future-feature {
  background-color: #bbbbbb;
  opacity: 0.3;
}

.new-feature-info-badge, .help-badge {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 999
}

textarea.mat-mdc-input-element.cdk-textarea-autosize {
  padding: unset;
}

body {
  //padding-top: 68px;
  //padding-bottom: 64px;
}

@media print {
  body {
    width: 8.5in;
    height: 11in;
    margin: 0.25in;
    padding: unset;
  }
}

.right {
  float: right;
}

.left {
  float: left;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}
